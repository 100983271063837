const moment = require('moment');
const { DB_FORMAT, USER_FORMAT } = require('../constants/Common');

const createDateContext = (locality_id, brand_id) => {
  if (!locality_id) {
    throw new Error('No locality id provided');
  }

  return { locality_id, brand_id };
};

/**
 * getIntervals
 * @param locality_id
 * @param brand_id
 * @param _year
 * @return {((moment.Moment|*)[]|(*|moment.Moment)[])[]}
 */
function getIntervals({ locality_id, brand_id }, _year = null) {
  const year = _year || (new Date()).getFullYear();
  const endOfDay = { hour: 23, minute: 59 };

  if (brand_id === 1 && [2].includes(locality_id)) {
    return [
      [moment({ months: 2, date: 26, year }), moment({ months: 10, date: 22, year, ...endOfDay })],
      [moment({ months: 10, date: 23, year }), moment({ months: 2, date: 25, year, ...endOfDay }).add({ years: 1 })],
      [moment({ months: 10, date: 23, year }).subtract({ years: 1 }), moment({ months: 2, date: 25, year, ...endOfDay })],
    ]
  }

  if (brand_id === 1 && [1].includes(locality_id)) {
    return [
      [moment({ months: 11, date: 26, year }).subtract({ years: 1 }), moment({ months: 9, date: 6, year, ...endOfDay })],
      [moment({ months: 9, date: 7, year }), moment({ months: 11, date: 25, year, ...endOfDay })],
      [moment({ months: 11, date: 26, year }), moment({ months: 5, date: 25, year, ...endOfDay }).add({ years: 1 })],
    ]
  }

  if (brand_id === 3) {
    return [
      [moment({ months: 2, date: 26, year }), moment({ months: 11, date: 25, year, ...endOfDay })],
      [moment({ months: 11, date: 26, year }).subtract({ years: 1 }), moment({ months: 2, date: 25, year, ...endOfDay })],
      [moment({ months: 11, date: 26, year }), moment({ months: 2, date: 25, year, ...endOfDay }).add({ years: 1 })],
    ]
  }

  return [
    [moment({ months: 11, date: 26, year }).subtract({ years: 1 }), moment({ months: 5, date: 25, year, ...endOfDay })],
    [moment({ months: 5, date: 26, year }), moment({ months: 11, date: 25, year, ...endOfDay })],
    [moment({ months: 11, date: 26, year }), moment({ months: 5, date: 25, year, ...endOfDay }).add({ years: 1 })],
  ];
}

function getIntervalIndex(context, dt, { useDtYear = false } = {}) {
  const dtm = moment(dt, [DB_FORMAT, USER_FORMAT, 'x'], false);
  if (!dtm.isValid()) {
    throw new Error('Bad datetime in get interval');
  }

  const year = useDtYear ? dtm.year() : null;
  const intervals = getIntervals(context, year);
  return intervals.findIndex(i => dtm.isBetween(i[0], i[1], null, '[]'));
}

/**
 * getInterval
 * @param {Object} context - locality_id, brand_id
 * @param {string|Object} dt - date
 * @param {number|null} [queryPeriod=null] - index of period
 * @return {{till: moment.Moment, from: moment.Moment}}
 */

function getInterval(context, dt, queryPeriod = null) {
  if (queryPeriod !== null) {
    const dtm = moment(dt, [DB_FORMAT, USER_FORMAT, 'x'], false);
    if (!dtm.isValid()) {
      throw new Error('Bad datetime in get interval');
    }

    const intervals = getIntervals(context, dtm.year());
    const interval = intervals[queryPeriod];
    return { from: interval[0], till: interval[1] };
  }

  const index = getIntervalIndex(context, dt);
  if (index === -1) {
    throw new Error(`Bad datetime in get interval between. Date ${dt}`);
  }

  const intervals = getIntervals(context);
  const interval = intervals[index];
  return { from: interval[0], till: interval[1] };
}

function isActivePeriod(dt_create) {
  const dt = moment(dt_create);
  const dtm = moment(Date.now());
  const interval = [];

  if (dtm.get('date') > 25) {
    interval[0] = dtm.set({ date: 26, hours: 0, minutes: 0 });
    interval[1] = interval[0].clone().subtract({ days: 1 }).add({ month: 1, hours: 23, minutes: 59 });
  } else {
    interval[0] = dtm.set({ date: 26, hours: 0, minutes: 0 }).subtract({ month: 1 });
    interval[1] = interval[0].clone().subtract({ days: 1 }).add({ month: 1, hours: 23, minutes: 59 });
  }

  return dt.isBetween(interval[0], interval[1], null, '[]');
}

function isPastYear(context, dt_create) {
  const [firstInterval] = getIntervals(context);
  const dtm = moment(dt_create);

  return firstInterval[0].isAfter(dtm);
}

module.exports = { createDateContext, getIntervals, getInterval, getIntervalIndex, isActivePeriod, isPastYear };
